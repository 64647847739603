import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="To Do List App Template | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/to-do-list-mobile-app/"
    metaDescription="Design the perfect to-do list mobile app with Uizard. With our chic mobile app template, you can bring your app design to life in minutes."
    description="
    h2:Discover Organic Mind; our mobile to do list app design template
    <br/>
    Open your mind to Organic Mind, Uizard's mobile to do list app UI template. Organic Mind comes with everything you would expect from a <a:https://uizard.io/prototyping/>UX/UI prototyping tool</a> design template including a stunning range of mockup screens and all the ideal user flows built right in. 
    <br/>
    h3:A mobile to do list app design to really impress
    <br/>
    As with all our mobile UI design templates, Organic Mind is endlessly customizable, meaning you can use the design right out the box, or you can spend as long as you want editing and updating. All our <a:https://uizard.io/templates/>UI templates</a> are built by professional designers as standard, guaranteeing you quality with whatever you are trying to design.
    <br/>
    h3:Complete your design to do list with Uizard, the design tool for non-designers
    <br/>
    From idea to wireframe, from wireframe to mockup, Uizard empowers you to design your own digital product, even if you have no design experience. Sign up to Uizard now and bring your to do list app design to life.
    "
    pages={[
      "A stunning, minimalist web app homepage",
      "An in-app interface page, demonstrating task list visualization, with task detail popups on click",
      "Subpages with task breakdown by custom parameters: 'Upcoming', 'Today', 'Calendar', and 'Sticky Wall'",
      "List pages to demonstrate bespoke categorization of tasks by type",
      "A search directory with various filters",
    ]}
    projectCode="aBYB4ZAAdbT5P79pYxEx"
    img1={data.image1.childImageSharp}
    img1alt="to do app design overview"
    img2={data.image2.childImageSharp}
    img2alt="to do app design calendar screen"
    img3={data.image3.childImageSharp}
    img3alt="to do app design sticky note screen"
    img4={data.image4.childImageSharp}
    img4alt="to do app design upcoming screen"
    img5={data.image5.childImageSharp}
    img5alt="to do app design summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/to-do-mobile-app/to-do-app-cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/to-do-mobile-app/to-do-app-calendar.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/to-do-mobile-app/to-do-app-sticky.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/to-do-mobile-app/to-do-app-upcoming.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/to-do-mobile-app/to-do-app-summary.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
